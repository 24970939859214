var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vtyPH7Cyqu2QHItz_hiSG"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/static-assets";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { sentryDsn as dsn } from './constants';

Sentry.init({
  dsn,
  tracesSampleRate: 0,
  integrations(integrations) {
    return integrations.filter(
      integration => integration.name !== 'Breadcrumbs'
    );
  },
  environment: process.env.NODE_ENV,
  allowUrls: [/^https:\/\/www\.buzzfeed\.com\/static-assets/],
  sampleRate: 0.01,
  attachStacktrace: true,
});

import ErrorPage from 'next/error';
import { ErrorBoundary } from '@buzzfeed/react-components';
import * as Sentry from '@sentry/nextjs';
import '../styles/globals.scss'

// local development - a11y logging
import '../utils/reactAxeSetup';

function Application({ Component, pageProps }) {
  return (
    <ErrorBoundary fallbackRender={() => (
        <ErrorPage
          statusCode={500}
          title="An unexpected error has occurred"
        />
      )}
      onError={(e) => {Sentry.captureException(e)}}
    >
      <Component {...pageProps} />
    </ErrorBoundary>
  );
}

export default Application;
